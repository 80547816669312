* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Encode Sans Expanded", sans-serif;
}

#root {
  white-space: pre-line;
}

body {
  padding-top: 80px;
}
