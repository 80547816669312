.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: hidden;
}

.popup-inner {
  position: relative;
  padding: 32px;
  width: 100%;
  max-width: 640px;
  z-index: 3;
  background-color: #fff;
  border-radius: 20px;
  overflow: scroll;
}

@media screen and (min-width: 320px) {
  .popup-inner {
    position: relative;
    padding: 32px 0;
    height: 550px;
    width: 80%;
    max-width: 640px;
    z-index: 3;
    background-color: #fff;
    border-radius: 20px;
    overflow: scroll;
  }
}

@media screen and (min-width: 641px) {
  .popup-inner {
    position: relative;
    padding: 32px;
    height: 275px;
    width: 85%;
    margin-top: 10%;
    max-width: 1100px;
    z-index: 3;
    background-color: #fff;
    border-radius: 20px;
    overflow: scroll;
  }
}
@media screen and (min-width: 961px) {
  .popup-inner {
    position: relative;
    padding: 32px;
    height: 550px;
    width: 100%;
    max-width: 1100px;
    z-index: 3;
    background-color: #fff;
    border-radius: 20px;
    overflow: scroll;
  }
}

.popup-inner .close-popup {
  position: sticky;
  top: 16px;
  right: 0;
  float: right;
  margin: 10px 5px 10px 10px;
  font-size: 30px;
  cursor: pointer;
}

.popup-inner h2 {
  padding: 20px 0 20px 20px;
}
