.portfolio-page {
  padding: 0 100px;
  position: initial;
  height: 100%;
  overflow: auto;
  background: #000;

  .images-container {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 100px;
    padding-top: 5%;
    justify-content: center;

    img:hover {
      opacity: 0.8;
    }
  }
}

@media screen and (min-width: 320px) {
  .container.portfolio-page {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
  }

  .images-container {
    gap: 10px;

    img {
      width: 110px;
      height: 110px;
    }
  }
}

@media screen and (min-width: 641px) {
  .container.portfolio-page {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
  }

  .images-container {
    gap: 10px;

    img {
      width: 150px;
      height: 150px;
    }
  }
}

@media only screen and (min-width: 896px) {
  .container.portfolio-page {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
  }

  .images-container {
    gap: 15px;

    img {
      width: 270px;
      height: 260px;
    }
  }
}
