@media screen and (min-width: 320px) {
  /* smartphones, iPhone, portrait 480x320 phones */
  .contact-form {
    justify-content: center;
    display: grid;
    grid-auto-columns: 1fr;
    /* grid-row-gap: 50px; */
    align-items: center;
    /* grid-template-areas: 'col1 col2'; */
  }

  .column1 {
    /* grid-area: col1; */
    padding: 15%;
    background-color: #d6d6d6;
  }

  .column1 .contact-title {
    text-align: center;
  }

  .column2 {
    /* grid-area: col2; */
    padding: 0 15px;
  }

  .contact-form form {
    border-radius: 10px;
    /* padding: 20px 20px 20px 20%; */
    padding: 40px 20px 20px 20px;
  }

  .contact-form ul li {
    /* width: 400px; */
    padding: 0;
    margin: 0;
    list-style: none;
    margin-bottom: 10px;
    overflow: hidden;
    display: block;
    clear: both;
    position: relative;
    animation: fadeInUp 2s 2s;
    animation-fill-mode: forwards;
  }

  .contact-form input[type="text"],
  .contact-form input[type="email"] {
    width: 100%;
    height: 50px;
    font-size: 16px;
    padding: 0 20px;
    box-sizing: border-box;
  }

  .contact-form select {
    width: 100%;
    height: 50px;
    font-size: 16px;
    padding: 0 20px;
    box-sizing: border-box;
  }

  #contact-submit {
    text-align: center;
  }

  .contact-form textarea {
    width: 100%;
    height: 50px;
    font-size: 16px;
    padding: 20px;
    box-sizing: border-box;
    min-height: 200px;
  }

  .contact-form .flat-button {
    height: 36px;
    width: 100px;
    background-color: #272727;
    color: #fff;
    font-size: 11px;
    letter-spacing: 3px;
    text-decoration: none;
    padding: 8px 10px;
    border: none;
    border-radius: 4px;
    text-transform: uppercase;
    margin-right: 10px;
  }

  .contact-form .flat-button:hover {
    transform: scale(1.05);
    opacity: 0.8;
    cursor: pointer;
  }

  .faq-section {
    padding-top: 20px;
  }

  .faq-section a span {
    color: #000;
  }

  .faq-wrap {
    margin: 20px 0;
  }

  .question-wrap {
    background-color: #272727;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    color: #fff;
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;
    cursor: pointer;
  }

  .question-wrap h4 {
    padding: 1rem;
  }

  .question-wrap h5 {
    /* font-size: 1.2rem; */
    padding-right: 10px;
    color: #fff;
  }

  .question-wrap span {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .answer-dropdown {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .answer-dropdown p span {
    font-size: 1.2rem;
    padding-right: 10px;
    font-weight: 600;
  }
}

@media screen and (min-width: 481px) {
  /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
  .contact-form {
    justify-content: center;
    align-items: center;
    display: grid;
    grid-auto-columns: 1fr 1fr;
    align-items: center;
    grid-template-areas: "col1 col2";
  }

  .column1 {
    grid-area: col1;
    padding: 15%;
    background-color: #d6d6d6;
    height: 100%;
  }

  .column2 {
    grid-area: col2;
    padding: 0 15px;
  }

  .contact-form form {
    border-radius: 10px;
    padding: 20px 20px 20px 20%;
  }

  .contact-form ul li {
    width: 400px;
    padding: 0;
    margin: 0;
    list-style: none;
    margin-bottom: 10px;
    overflow: hidden;
    display: block;
    clear: both;
    position: relative;
    animation: fadeInUp 2s 2s;
    animation-fill-mode: forwards;
  }

  .contact-form input[type="text"],
  .contact-form input[type="email"] {
    width: 100%;
    height: 50px;
    font-size: 16px;
    padding: 0 20px;
    box-sizing: border-box;
  }

  .contact-form select {
    width: 100%;
    height: 50px;
    font-size: 16px;
    padding: 0 20px;
    box-sizing: border-box;
  }

  #contact-submit {
    text-align: center;
  }

  .contact-form textarea {
    width: 100%;
    height: 50px;
    font-size: 16px;
    padding: 20px;
    box-sizing: border-box;
    min-height: 200px;
  }

  .contact-form .flat-button {
    height: 36px;
    width: 100px;
    background-color: #272727;
    color: #fff;
    font-size: 11px;
    letter-spacing: 3px;
    text-decoration: none;
    padding: 8px 10px;
    border: none;
    border-radius: 4px;
    text-transform: uppercase;
    margin-right: 10px;
  }

  .contact-form .flat-button:hover {
    transform: scale(1.05);
    opacity: 0.8;
    cursor: pointer;
  }

  .faq-section {
    padding-top: 20px;
  }

  .faq-section a span {
    color: #000;
  }

  .faq-wrap {
    margin: 20px 0;
  }

  .question-wrap {
    background-color: #272727;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 50px;
    cursor: pointer;
  }

  .question-wrap h4 {
    padding: 1rem;
  }

  .question-wrap h4 span {
    font-size: 1.2rem;
    padding-right: 10px;
    color: #fff;
  }

  .question-wrap span {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .answer-dropdown {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .answer-dropdown p span {
    font-size: 1.2rem;
    padding-right: 10px;
    color: #fff;
    font-weight: 600;
  }
}

@media screen and (min-width: 641px) {
  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
  .contact-form {
    justify-content: center;
    align-items: center;
    display: grid;
    grid-auto-columns: 1fr 1fr;
    align-items: center;
    grid-template-areas: "col1 col2";
    /* grid-row-gap: 100px; */
  }

  .column1 {
    grid-area: col1;
    padding: 15%;
    background-color: #d6d6d6;
    height: 100%;
  }

  .column2 {
    grid-area: col2;
    padding: 0 15px;
  }

  .contact-form form {
    border-radius: 10px;
    padding: 30px 0 30px 20px;
  }

  .contact-form ul li {
    width: 300px;
    padding: 0;
    margin: 0;
    list-style: none;
    margin-bottom: 10px;
    overflow: hidden;
    display: block;
    clear: both;
    position: relative;
    animation: fadeInUp 2s 2s;
    animation-fill-mode: forwards;
  }

  .contact-form input[type="text"],
  .contact-form input[type="email"] {
    width: 100%;
    height: 50px;
    font-size: 16px;
    padding: 0 20px;
    box-sizing: border-box;
  }

  .contact-form select {
    width: 100%;
    height: 50px;
    font-size: 16px;
    padding: 0 20px;
    box-sizing: border-box;
  }

  #contact-submit {
    text-align: center;
  }

  .contact-form textarea {
    width: 100%;
    height: 50px;
    font-size: 16px;
    padding: 20px;
    box-sizing: border-box;
    min-height: 200px;
  }

  .contact-form .flat-button {
    height: 36px;
    width: 100px;
    background-color: #272727;
    color: #fff;
    font-size: 11px;
    letter-spacing: 3px;
    text-decoration: none;
    padding: 8px 10px;
    border: none;
    border-radius: 4px;
    text-transform: uppercase;
    margin-right: 10px;
  }

  .contact-form .flat-button:hover {
    transform: scale(1.05);
    opacity: 0.8;
    cursor: pointer;
  }

  .faq-section {
    padding-top: 20px;
  }

  .faq-section a span {
    color: #000;
  }

  .faq-wrap {
    margin: 20px 0;
  }

  .question-wrap {
    background-color: #272727;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 50px;
    cursor: pointer;
  }

  .question-wrap h4 {
    padding: 1rem;
  }

  .question-wrap h4 span {
    font-size: 1.2rem;
    padding-right: 10px;
    color: #fff;
  }

  .question-wrap span {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .answer-dropdown {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .answer-dropdown p span {
    font-size: 1.2rem;
    padding-right: 10px;
    color: #fff;
    font-weight: 600;
  }
}

@media screen and (min-width: 961px) {
  /* big landscape tablets, laptops, and desktops */
  .contact-form {
    justify-content: center;
    align-items: center;
    display: grid;
    grid-auto-columns: 1fr 1fr;
    align-items: center;
    grid-template-areas: "col1 col2";
  }

  .column1 {
    grid-area: col1;
    padding: 15%;
    background-color: #d6d6d6;
  }

  .column2 {
    grid-area: col2;
    padding: 0 15px;
  }

  .contact-form form {
    border-radius: 10px;
    padding: 20px 20px 20px 20%;
  }

  .contact-form ul li {
    width: 400px;
    padding: 0;
    margin: 0;
    list-style: none;
    margin-bottom: 10px;
    overflow: hidden;
    display: block;
    clear: both;
    position: relative;
    animation: fadeInUp 2s 2s;
    animation-fill-mode: forwards;
  }

  .contact-form input[type="text"],
  .contact-form input[type="email"] {
    width: 100%;
    height: 50px;
    font-size: 16px;
    padding: 0 20px;
    box-sizing: border-box;
  }

  .contact-form select {
    width: 100%;
    height: 50px;
    font-size: 16px;
    padding: 0 20px;
    box-sizing: border-box;
  }

  #contact-submit {
    text-align: center;
  }

  .contact-form textarea {
    width: 100%;
    height: 50px;
    font-size: 16px;
    padding: 20px;
    box-sizing: border-box;
    min-height: 200px;
  }

  .contact-form .flat-button {
    height: 36px;
    width: 100px;
    background-color: #272727;
    color: #fff;
    font-size: 11px;
    letter-spacing: 3px;
    text-decoration: none;
    padding: 8px 10px;
    border: none;
    border-radius: 4px;
    text-transform: uppercase;
    margin-right: 10px;
  }

  .contact-form .flat-button:hover {
    transform: scale(1.05);
    opacity: 0.8;
    cursor: pointer;
  }

  .faq-section {
    padding-top: 20px;
  }

  .faq-section a span {
    color: #000;
  }

  .faq-wrap {
    margin: 20px 0;
  }

  .question-wrap {
    background-color: #272727;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 50px;
    cursor: pointer;
  }

  .question-wrap h4 {
    padding: 1rem;
  }

  .question-wrap h4 span {
    font-size: 1.2rem;
    padding-right: 10px;
    color: #fff;
  }

  .question-wrap span {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .answer-dropdown {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .answer-dropdown p span {
    font-size: 1.2rem;
    padding-right: 10px;
    color: #fff;
    font-weight: 600;
  }
}

@media screen and (min-width: 1281px) {
  /* hi-res laptops and desktops */
  .contact-form {
    justify-content: center;
    align-items: center;
    display: grid;
    grid-auto-columns: 1fr 1fr;
    align-items: center;
    grid-template-areas: "col1 col2";
    height: 80vh;
    overflow: auto;
  }

  .column1 {
    grid-area: col1;
    padding: 15%;
    background-color: #d6d6d6;
  }

  .column1 .contact-title {
    font-size: 2.5rem;
  }

  .column2 {
    grid-area: col2;
    padding: 0 15px;
  }

  .contact-form form {
    border-radius: 10px;
    padding: 20px 20px 20px 20%;
  }

  .contact-form ul li {
    width: 400px;
    padding: 0;
    margin: 0;
    list-style: none;
    margin-bottom: 10px;
    overflow: hidden;
    display: block;
    clear: both;
    position: relative;
    animation: fadeInUp 2s 2s;
    animation-fill-mode: forwards;
  }

  .contact-form input[type="text"],
  .contact-form input[type="email"] {
    width: 100%;
    height: 50px;
    font-size: 16px;
    padding: 0 20px;
    box-sizing: border-box;
  }

  .contact-form select {
    width: 100%;
    height: 50px;
    font-size: 16px;
    padding: 0 20px;
    box-sizing: border-box;
  }

  #contact-submit {
    text-align: center;
  }

  .contact-form textarea {
    width: 100%;
    height: 50px;
    font-size: 16px;
    padding: 20px;
    box-sizing: border-box;
    min-height: 200px;
  }

  .contact-form .flat-button {
    height: 36px;
    width: 100px;
    background-color: #272727;
    color: #fff;
    font-size: 11px;
    letter-spacing: 3px;
    text-decoration: none;
    padding: 8px 10px;
    border: none;
    border-radius: 4px;
    text-transform: uppercase;
    margin-right: 10px;
  }

  .contact-form .flat-button:hover {
    transform: scale(1.05);
    opacity: 0.8;
    cursor: pointer;
  }

  .faq-section {
    padding-top: 20px;
  }

  .faq-section a span {
    color: #000;
  }

  .faq-wrap {
    margin: 20px 0;
  }

  .question-wrap {
    background-color: #272727;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 50px;
    cursor: pointer;
  }

  .question-wrap h5 {
    padding: 1rem;
    font-size: 1rem;
  }

  .question-wrap h5 span {
    font-size: 1.2rem;
    padding-right: 10px;
    color: #fff;
  }

  .question-wrap span {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .answer-dropdown {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .answer-dropdown p span {
    font-size: 1.2rem;
    padding-right: 10px;
    color: #fff;
    font-weight: 600;
  }
}
