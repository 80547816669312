.dashboard {
  justify-content: center;
  align-items: center;
  margin: 10%;
}

.dashboard form {
  border-radius: 10px;
  padding: 40px 20px 20px 20px;
  width: 60%;
  border: 3px solid #000;
}

.dashboard form ul li {
  padding: 20px;
  margin: 0;
  list-style: none;
  margin-bottom: 10px;
  overflow: hidden;
  display: block;
  clear: both;
  position: relative;
  animation: fadeInUp 2s 2s;
  animation-fill-mode: forwards;
}

.dashboard form .flat-button {
  height: 36px;
  width: 100px;
  background-color: #272727;
  color: #fff;
  font-size: 11px;
  letter-spacing: 3px;
  text-decoration: none;
  padding: 8px 10px;
  border: none;
  border-radius: 4px;
  text-transform: uppercase;
  margin-right: 10px;
}

.dashboard form .flat-button:hover {
  transform: scale(1.05);
  opacity: 0.8;
  cursor: pointer;
}
