@media screen and (min-width: 320px) {
  .column {
    /* float: left; */
    /* width: 50%; */
    padding: 10px;
    /* height: 800px; */
  }

  /* Clear floats after the columns */
  .row:after {
    content: "";
    display: table;
    clear: both;
  }

  #col1 {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #col1 h2 {
    padding-bottom: 30px;
  }

  #col1 .text-container {
    display: flex;
    flex-wrap: wrap;
    padding-top: 15%;
    justify-content: center;
    text-align: center;
  }

  #col1 .text-container .card {
    background-color: #fff;
    padding: 25px 0;
    width: 75%;
  }

  #col1 .text-container .card h4 {
    padding-bottom: 10px;
  }

  #col1 .text-container .card p {
    font-size: 0.8rem;
  }

  #col2 {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #col2 img {
    height: 400px;
  }
}

@media screen and (min-width: 481px) {
  .column {
    float: left;
    /* width: 50%; */
    padding-top: 50px;
    /* height: 800px; */
  }

  .left {
    width: 55%;
  }

  .right {
    width: 45%;
  }

  /* Clear floats after the columns */
  .row:after {
    content: "";
    display: table;
    clear: both;
  }

  #col1 {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #col1 h2 {
    padding-bottom: 30px;
  }

  #col1 .text-container {
    display: flex;
    flex-wrap: wrap;
    padding-top: 5%;
    justify-content: center;
    text-align: center;
  }

  #col1 .text-container .card {
    background-color: #fff;
    padding: 25px 0;
    width: 75%;
  }

  #col1 .text-container .card h4 {
    padding-bottom: 10px;
  }

  #col2 {
    display: flex;
    align-items: center;
    height: 700px;
    padding-right: 20px;
  }

  #col2 img {
    height: 350px;
  }
}

@media screen and (min-width: 961px) {
  .column {
    float: left;
    padding: 10px;
    height: 800px;
  }

  .left {
    width: 60%;
  }

  .right {
    width: 40%;
  }

  /* Clear floats after the columns */
  .row:after {
    content: "";
    display: table;
    clear: both;
  }

  #col1 {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #col1 h2 {
    padding-bottom: 30px;
  }

  #col1 .text-container {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 100px;
    padding-top: 5%;
    justify-content: center;
    text-align: center;
  }

  #col1 .text-container .card {
    background-color: #fff;
    padding: 25px 0;
    width: 75%;
  }

  #col1 .text-container .card h4 {
    padding-bottom: 10px;
  }

  #col1 .text-container .card p {
    font-size: 1rem;
  }

  #col2 {
    display: flex;
    justify-content: left;
    align-items: center;
  }

  #col2 img {
    height: 600px;
  }
}
